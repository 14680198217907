// constants.ts
export enum FOCUSING {
  WINDOW = 'WINDOW',
  ICON = 'ICON',
  DESKTOP = 'DESKTOP',
}

export enum POWER_STATE {
  START = 'START',
  LOG_OFF = 'LOG_OFF',
  TURN_OFF = 'TURN_OFF',
}
