import React from 'react';

const Intro = () => {
  return (
    <div className="w-full min-h-screen bg-black text-white p-5 font-mono">
      <div> Press any key to start </div>
      <div className="animate-blink">_</div>
    </div>
  );
};

export default Intro;
