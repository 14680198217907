import React, { useState, useEffect } from 'react';
import WinXP from 'WinXP';
import Intro from '../intro/intro';

const BlueScreen = () => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const handleKeyPress = () => setIsVisible(false);

    // Add event listener for keydown
    window.addEventListener('keydown', handleKeyPress);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, []);

  if (!isVisible) {
    window.location.reload();
    return <Intro />;
  } // Return null if the component should be hidden

  return (
    <div
      className="bg-[#00007a] text-white h-screen w-full select-none"
      style={{ fontFamily: "'Lucida Console', 'Courier New', monospace" }}
    >
      <div className="text-2xl p-10">
        <h1 className="font-bold mb-14">
          A problem has been detected and the application could not be started.
        </h1>
        <p className="font-bold mb-8">ERROR: APPLICATION_NOT_FOUND</p>
        <p className="mb-8">
          The system was unable to locate the specified application. This error
          may occur if the application was moved, deleted, or its path was
          misconfigured.
        </p>

        <div className="">
          <p>
            <strong>Technical Information:</strong>
          </p>
          <p>
            STOP: 0x0000007B (0x00000000, 0x00000002, 0x00000001, 0x804D9B64)
          </p>
          <p>APPLICATION_PATH_NOT_FOUND</p>
        </div>

        <p className="mb-8">
          If this is the first time you've seen this Stop error screen, try the
          following steps:
        </p>

        <ul className="list-disc list-inside mb-4">
          <li>Verify that the application path is correct and accessible.</li>
          <li>
            Ensure that the application files have not been moved or deleted.
          </li>
          <li>Reinstall the application if necessary.</li>
          <li>Contact technical support for further assistance.</li>
        </ul>

        <p className="mt-8 font-bold">Press any key to continue...</p>
      </div>
    </div>
  );
};

export default BlueScreen;
