import InternetExplorer from './InternetExplorer';
import Minesweeper from './Minesweeper';
import ErrorBox from './ErrorBox';
import MyComputer from './MyComputer';
import Notepad from './Notepad';
import Winamp from './Winamp';
import Paint from './Paint';
import iePaper from 'assets/windowsIcons/ie-paper.png';
import ie from 'assets/windowsIcons/ie.png';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import mine from 'assets/minesweeper/mine-icon.png';
import error from 'assets/windowsIcons/897(16x16).png';
import computer from 'assets/windowsIcons/676(16x16).png';
import computerLarge from 'assets/windowsIcons/676(32x32).png';
import notepad from 'assets/windowsIcons/327(16x16).png';
import notepadLarge from 'assets/windowsIcons/327(32x32).png';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import winamp from 'assets/windowsIcons/winamp.png';
import paintLarge from '../../assets/windowsIcons/680(32x32).png';
import paint from 'assets/windowsIcons/680(16x16).png';
import cs16Icon from 'assets/cs-1-6.png';
import shibaInuIcon from 'assets/shiba-inu.png';
import msnIcon from 'assets/msn-icon.png';
import gptIcon from 'assets/chatgpt-icon.png';
import twitterIcon from 'assets/twitter-icon.png';
import aaveIcon from 'assets/aave-icon.png';
import diabloIcon from 'assets/diablo-2.png';
import maxPayneIcon from 'assets/max-payne.png';
import simsIcon from 'assets/the-sims-2000.png';
import thalia32Icon from 'assets/thalia-32.png';
import flashBotIcon from 'assets/flashbot-logo.png';
import jitoLabsIcon from 'assets/jitolabs-logo.png';
import JupiterCoinIcon from 'assets/jupiter-logo.png';
import lunarCrushIcon from 'assets/lunarcrush-logo.png';
import raydiumIcon from 'assets/raydium-logo.png';
import solanaIcon from 'assets/solana-logo.png';
import SolanaTrackerIcon from 'assets/solana-tracker.png';
import DaosIcon from 'assets/daos-icon.png';

const gen = () => {
  let id = -1;
  return () => {
    id += 1;
    return id;
  };
};
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const genId = gen();
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const genIndex = gen();

export const defaultAppState: App[] = [];

export const defaultIconState: Icon[] = [
  {
    id: 0,
    icon: ie,
    title: 'Internet Explorer',
    component: InternetExplorer,
    isFocus: false,
    // url: 'https://www.medium.com/',
  },
  {
    id: 1,
    icon: computerLarge,
    title: 'My Computer',
    component: MyComputer,
    isFocus: false,
  },
  {
    id: 2,
    icon: notepadLarge,
    title: 'Notepad',
    component: Notepad,
    isFocus: false,
  },
  {
    id: 3,
    icon: paintLarge,
    title: 'Paint',
    component: Paint,
    isFocus: false,
  },
  {
    id: 4,
    icon: msnIcon,
    title: 'MSN',
    component: ErrorBox,
    isFocus: false,
    url: 'https://web.telegram.org/',
  },
  {
    id: 5,
    icon: twitterIcon,
    title: 'Twitter',
    component: ErrorBox,
    isFocus: false,
    url: 'https://twitter.com/',
  },
  {
    id: 6,
    icon: thalia32Icon,
    title: '32 Thalia',
    component: ErrorBox,
    isFocus: false,
    url: 'https://chatgpt.com/g/g-67410a184b08819185050cd79f591a99-thalia-32',
  },
  {
    id: 7,
    icon: cs16Icon,
    title: 'Counter-Strike 1.6',
    component: ErrorBox,
    isFocus: false,
  },
  {
    id: 8,
    icon: diabloIcon,
    title: 'Diablo II',
    component: ErrorBox,
    isFocus: false,
  },
  {
    id: 9,
    icon: maxPayneIcon,
    title: 'Max Payne',
    component: ErrorBox,
    isFocus: false,
  },
  {
    id: 10,
    icon: simsIcon,
    title: 'The Sims',
    component: ErrorBox,
    isFocus: false,
  },
  {
    id: 11,
    icon: shibaInuIcon,
    title: 'Shiba-Inu',
    component: ErrorBox,
    isFocus: false,
  },
  {
    id: 12,
    icon: flashBotIcon,
    title: 'FlashBot',
    component: ErrorBox,
    isFocus: false,
  },
  {
    id: 13,
    icon: jitoLabsIcon,
    title: 'Jito Labs',
    component: ErrorBox,
    isFocus: false,
  },
  {
    id: 14,
    icon: JupiterCoinIcon,
    title: 'Jupiter Coin',
    component: ErrorBox,
    isFocus: false,
  },
  {
    id: 15,
    icon: lunarCrushIcon,
    title: 'Lunar Crush',
    component: ErrorBox,
    isFocus: false,
  },
  {
    id: 16,
    icon: raydiumIcon,
    title: 'Raydium',
    component: ErrorBox,
    isFocus: false,
  },
  {
    id: 17,
    icon: solanaIcon,
    title: 'Solana',
    component: ErrorBox,
    isFocus: false,
  },
  {
    id: 18,
    icon: SolanaTrackerIcon,
    title: 'Solana Tracker',
    component: ErrorBox,
    isFocus: false,
  },
  {
    id: 19,
    icon: aaveIcon,
    title: 'Aave',
    component: ErrorBox,
    isFocus: false,
  },
  {
    id: 20,
    icon: DaosIcon,
    title: 'DAOs.fun',
    component: ErrorBox,
    isFocus: false,
    url: 'https://daos.fun',
  },
];

export const appSettings: AppSettings = {
  'Internet Explorer': {
    header: {
      icon: iePaper,
      title: 'Internet Explorer',
    },
    component: InternetExplorer,
    defaultSize: {
      width: 700,
      height: 500,
    },
    defaultOffset: {
      x: 140,
      y: 30,
    },
    resizable: true,
    minimized: false,
    maximized: window.innerWidth < 800,
    multiInstance: true,
  },
  Error: {
    header: {
      icon: error,
      title: 'C:\\',
      buttons: ['close'],
      noFooterWindow: true,
    },
    component: ErrorBox,
    defaultSize: {
      width: 380,
      height: 0,
    },
    defaultOffset: {
      x: window.innerWidth / 2 - 190,
      y: window.innerHeight / 2 - 60,
    },
    resizable: false,
    minimized: false,
    maximized: false,
    multiInstance: true,
  },
  'My Computer': {
    header: {
      icon: computer,
      title: 'My Computer',
    },
    component: MyComputer,
    defaultSize: {
      width: 660,
      height: 500,
    },
    defaultOffset: {
      x: 260,
      y: 50,
    },
    resizable: true,
    minimized: false,
    maximized: window.innerWidth < 800,
    multiInstance: false,
  },
  Notepad: {
    header: {
      icon: notepad,
      title: 'Untitled - Notepad',
    },
    component: Notepad,
    defaultSize: {
      width: 660,
      height: 500,
    },
    defaultOffset: {
      x: 270,
      y: 60,
    },
    resizable: true,
    minimized: false,
    maximized: window.innerWidth < 800,
    multiInstance: true,
  },
  Paint: {
    header: {
      icon: paint,
      title: 'Untitled - Paint',
    },
    component: Paint,
    defaultSize: {
      width: 660,
      height: 500,
    },
    defaultOffset: {
      x: 280,
      y: 70,
    },
    resizable: true,
    minimized: false,
    maximized: window.innerWidth < 800,
    multiInstance: true,
  },
  Twitter: {
    header: {
      icon: twitterIcon,
      title: 'Twitter',
    },
    component: ErrorBox,
    defaultSize: {
      width: window.innerWidth,
      height: window.innerHeight,
    },
    defaultOffset: {
      x: 0,
      y: 0,
    },
    resizable: false,
    minimized: false,
    maximized: true,
    multiInstance: false,
    fullscreenBlueScreen: false,
  },
  'Shiba-Inu': {
    header: {
      icon: shibaInuIcon,
      title: 'Shiba Inu',
    },
    component: ErrorBox,
    defaultSize: {
      width: window.innerWidth,
      height: window.innerHeight,
    },
    defaultOffset: {
      x: 0,
      y: 0,
    },
    resizable: false,
    minimized: false,
    maximized: true,
    multiInstance: false,
    fullscreenBlueScreen: false,
  },
  MSN: {
    header: {
      icon: msnIcon,
      title: 'MSN',
    },
    component: ErrorBox,
    defaultSize: {
      width: window.innerWidth,
      height: window.innerHeight,
    },
    defaultOffset: {
      x: 0,
      y: 0,
    },
    resizable: false,
    minimized: false,
    maximized: true,
    multiInstance: false,
    fullscreenBlueScreen: false,
  },
  Aave: {
    header: {
      icon: aaveIcon,
      title: 'Aave',
    },
    component: ErrorBox,
    defaultSize: {
      width: window.innerWidth,
      height: window.innerHeight,
    },
    defaultOffset: {
      x: 0,
      y: 0,
    },
    resizable: false,
    minimized: false,
    maximized: true,
    multiInstance: false,
    fullscreenBlueScreen: false,
  },
  '32 Agent': {
    header: {
      icon: thalia32Icon,
      title: '32 Agent',
    },
    component: ErrorBox,
    defaultSize: {
      width: window.innerWidth,
      height: window.innerHeight,
    },
    defaultOffset: {
      x: 0,
      y: 0,
    },
    resizable: false,
    minimized: false,
    maximized: true,
    multiInstance: false,
    fullscreenBlueScreen: false,
  },
  'Counter-Strike 1.6': {
    header: {
      icon: cs16Icon,
      title: 'Counter-Strike 1.6',
    },
    component: ErrorBox,
    defaultSize: {
      width: window.innerWidth,
      height: window.innerHeight,
    },
    defaultOffset: {
      x: 0,
      y: 0,
    },
    resizable: false,
    minimized: false,
    maximized: true,
    multiInstance: false,
    fullscreenBlueScreen: false,
  },
  'Diablo II': {
    header: {
      icon: diabloIcon,
      title: 'Diablo II',
    },
    component: ErrorBox,
    defaultSize: {
      width: window.innerWidth,
      height: window.innerHeight,
    },
    defaultOffset: {
      x: 0,
      y: 0,
    },
    resizable: false,
    minimized: false,
    maximized: true,
    multiInstance: false,
    fullscreenBlueScreen: false,
  },
  'Max Payne': {
    header: {
      icon: maxPayneIcon,
      title: 'Max Payne',
    },
    component: ErrorBox,
    defaultSize: {
      width: window.innerWidth,
      height: window.innerHeight,
    },
    defaultOffset: {
      x: 0,
      y: 0,
    },
    resizable: false,
    minimized: false,
    maximized: true,
    multiInstance: false,
    fullscreenBlueScreen: false,
  },
  'The Sims': {
    header: {
      icon: simsIcon,
      title: 'The Sims',
    },
    component: ErrorBox,
    defaultSize: {
      width: window.innerWidth,
      height: window.innerHeight,
    },
    defaultOffset: {
      x: 0,
      y: 0,
    },
    resizable: false,
    minimized: false,
    maximized: true,
    multiInstance: false,
    fullscreenBlueScreen: false,
  },
  Daos: {
    header: {
      icon: DaosIcon,
      title: 'DAOs.fun',
    },
    component: ErrorBox,
    defaultSize: {
      width: window.innerWidth,
      height: window.innerHeight,
    },
    defaultOffset: {
      x: 0,
      y: 0,
    },
    resizable: false,
    minimized: false,
    maximized: true,
    multiInstance: false,
    fullscreenBlueScreen: false,
  },
};

export { InternetExplorer, Minesweeper, ErrorBox, MyComputer, Notepad, Winamp };

interface Icon {
  id: number;
  icon: string;
  title: string;
  component: React.ElementType;
  isFocus: boolean;
  url?: string;
}

interface AppSetting {
  header: {
    icon: string;
    title: string;
    buttons?: string[];
    noFooterWindow?: boolean;
    invisible?: boolean; // Add this line
  };
  component: React.ElementType;
  defaultSize: {
    width: number;
    height: number;
  };
  defaultOffset: {
    x: number;
    y: number;
  };
  resizable: boolean;
  minimized: boolean;
  maximized: boolean;
  multiInstance?: boolean;
  injectProps?: {
    message?: string;
  };
  fullscreenBlueScreen?: boolean;
}

interface App extends AppSetting {
  id: number;
  zIndex: number;
}

interface AppSettings {
  [key: string]: AppSetting;
}
