// WindowLoadingIntro.jsx
import React, { useState, useEffect } from 'react';

const WindowLoadingIntro = () => {
  const [fadeOut, setFadeOut] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setFadeOut(true);
    }); // Delay before starting fade out

    return () => clearTimeout(timer); // Clean up timer on unmount
  }, []);

  return (
    <div className={`w-full min-h-screen bg-black text-white `}>
      <div className="intro-window">
        <div
          className={`intro-logo transition-opacity duration-[2000ms] ${
            fadeOut ? 'opacity-100' : 'opacity-70'
          }`}
        >
          <p className="intro-mid flex">
            Windows<span>XP</span>
          </p>
          <p className="intro-bottom">Professional</p>
        </div>
        <div className="intro-container">
          <div className="intro-box"></div>
          <div className="intro-box"></div>
          <div className="intro-box"></div>
        </div>
      </div>
    </div>
  );
};

export default WindowLoadingIntro;
