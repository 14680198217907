import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';

interface IconRect {
  id: number;
  x: number;
  y: number;
  w: number;
  h: number;
}

interface MouseState {
  docX: number;
  docY: number;
}

interface SelectingState {
  x: number;
  y: number;
}

export interface IconProps {
  id: number;
  title: string;
  icon: string;
  className?: string;
  component: React.ElementType;
  onMouseDown: (id: number) => void;
  onDoubleClick: (component: React.ElementType) => void;
  measure: (rect: IconRect) => void;
  displayFocus: boolean;
  isFocus: boolean;
  url?: string;
}

export interface IconsProps {
  icons: IconProps[];
  mouse: MouseState;
  selecting: SelectingState | null;
  setSelectedIcons: (iconIds: number[]) => void;
  displayFocus: boolean;
  appSettings: any;
}

const Icons: React.FC<IconsProps> = ({
  icons,
  mouse,
  selecting,
  setSelectedIcons,
}) => {
  const [iconsRect, setIconsRect] = useState<IconRect[]>([]);

  const measure = (rect: IconRect) => {
    setIconsRect(prevRects => {
      if (prevRects.some(r => r.id === rect.id)) return prevRects;
      return [...prevRects, rect];
    });
  };

  useEffect(() => {
    if (!selecting) return;
    const sx = Math.min(selecting.x, mouse.docX);
    const sy = Math.min(selecting.y, mouse.docY);
    const sw = Math.abs(selecting.x - mouse.docX);
    const sh = Math.abs(selecting.y - mouse.docY);

    const selectedIds = iconsRect
      .filter(rect => {
        const { x, y, w, h } = rect;
        return x < sx + sw && x + w > sx && y < sy + sh && y + h > sy;
      })
      .map(icon => icon.id);

    setSelectedIcons(selectedIds);
  }, [iconsRect, setSelectedIcons, selecting, mouse.docX, mouse.docY]);

  return (
    <div className="w-fit mt-10 ml-10 flex flex-col flex-wrap  max-h-[300px] h-sm:max-h-[600px] h-md:max-h-[700px] h-lg:max-h-[850px] gap-2">
      {icons.map(icon => (
        <StyledIcon key={icon.id} {...icon} measure={measure} />
      ))}
    </div>
  );
};

const Icon: React.FC<IconProps> = ({
  id,
  title,
  icon,
  className,
  component,
  onMouseDown,
  onDoubleClick,
  measure,
  displayFocus,
  isFocus,
  url,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const isMobileDevice = () => {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent,
    );
  };

  const handleMouseDown = () => {
    onMouseDown(id);
  };

  const handleClick = () => {
    if (isMobileDevice()) {
      if (url) {
        window.open(url, '_blank');
      } else {
        onDoubleClick(component);
      }
    }
  };

  const handleDoubleClick = () => {
    if (!isMobileDevice()) {
      if (url) {
        window.open(url, '_blank');
      } else {
        onDoubleClick(component);
      }
    }
  };

  useEffect(() => {
    const target = ref.current;
    if (!target) return;
    const { left, top, width, height } = target.getBoundingClientRect();
    const posX = left + window.scrollX;
    const posY = top + window.scrollY;
    measure({ id, x: posX, y: posY, w: width, h: height });
  }, [id, measure]);

  return (
    <div
      className={className}
      onMouseDown={handleMouseDown}
      onClick={handleClick}
      onDoubleClick={handleDoubleClick}
      ref={ref}
    >
      <div className={`${className}__img__container`}>
        <img src={icon} alt={title} className={`${className}__img`} />
      </div>
      <div className={`${className}__text__container`}>
        <div className={`${className}__text`}>{title}</div>
      </div>
    </div>
  );
};

const StyledIcon = styled(Icon)<{ isFocus?: boolean; displayFocus: boolean }>`
  width: 70px;
  height: 50px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__text__container {
    width: 100%;
    font-size: 10px;
    color: white;
    text-shadow: 0 1px 1px black;
    margin-top: 5px;
    display: flex;
    justify-content: center;

    &:before,
    &:after {
      content: '';
      display: block;
      flex-grow: 1;
    }
  }

  &__text {
    padding: 0 3px 2px;
    background-color: ${({ isFocus, displayFocus }) =>
      isFocus && displayFocus ? '#0b61ff' : 'transparent'};
    text-align: center;
    flex-shrink: 1;
  }

  &__img__container {
    width: 30px;
    height: 30px;
    filter: ${({ isFocus, displayFocus }) =>
      isFocus && displayFocus ? 'drop-shadow(0 0 blue)' : 'none'};
  }

  &__img {
    width: 30px;
    height: 30px;
    opacity: ${({ isFocus, displayFocus }) =>
      isFocus && displayFocus ? 0.5 : 1};
  }
`;

export default Icons;
