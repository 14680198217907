import React, { useEffect } from 'react';
import styled from 'styled-components';

import errorSoundSrc from 'assets/sounds/error.wav';
import error from 'assets/windowsIcons/897(32x32).png';

function lineBreak(str) {
  return str.split('\n').map((s, i) => (
    <p key={i} className="error__message">
      {s}
    </p>
  ));
}

function Error({ onClose, message = "Something's wrong!" }) {
  useEffect(() => {
    try {
      new Audio(errorSoundSrc).play();
    } catch (e) {
      console.log(e);
    }
  }, []);
  return (
    <div className="flex h-full w-full flex-col bg-[#f5f5f5] p-3 text-[11px]">
      <div className="flex flex-1">
        <img src={error} alt="error" className="h-[30px] w-[30px]" />
        <div className="px-5 pb-3 pt-0.5">
          {message.split('\n').map((s, i) => (
            <p key={i} className="leading-4">
              {s}
            </p>
          ))}
        </div>
      </div>
      <div className="flex w-full justify-center">
        <div
          onClick={onClose}
          className="flex h-[22px] w-[80px] cursor-pointer items-center justify-center border border-black shadow-[inset_-1px_-1px_1px_black] active:shadow-[inset_1px_1px_1px_black]"
        >
          <span className="leading-[11px] active:translate-x-[1px] active:translate-y-[1px]">
            OK
          </span>
        </div>
      </div>
    </div>
  );
}

// const Div = styled.div`
//   background-color: #f5f5f5;
//   width: 100%;
//   height: 100%;
//   font-size: 11px;
//   padding: 12px;
//   display: flex;
//   flex-direction: column;
//   .error__top {
//     display: flex;
//     flex: 1;
//   }
//   .error__img {
//     width: 30px;
//     height: 30px;
//   }
//   .error__messages {
//     padding: 2px 20px 12px;
//   }
//   .error__message {
//     line-height: 16px;
//   }
//   .error__bottom {
//     display: flex;
//     width: 100%;
//     justify-content: center;
//   }
//   .error__button {
//     width: 80px;
//     height: 22px;
//     display: flex;
//     border: 1px solid black;
//     justify-content: center;
//     align-items: center;
//     box-shadow: inset -1px -1px 1px black;
//     &:hover:active {
//       box-shadow: inset 1px 1px 1px black;
//       & > * {
//         transform: translate(1px, 1px);
//       }
//     }
//   }
//   .error__confirm {
//     line-height: 11px;
//   }
// `;

export default Error;
